<template>
  <div class="container">
    <div class="block" style="margin-bottom:10px">
      <el-button
        type="success"
        icon="el-icon-plus"
        @click="dialogVisible = true"
      >
        新增
      </el-button>
    </div>
    <el-table
      v-loading="listLoading"
      :data="dataList"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      >
      <el-table-column
        prop="name"
        label="套餐名称"
        align="center"
        width="200"
      ></el-table-column>
      <el-table-column prop="content" label="内容" align="center">
        <template slot-scope="{ row }">{{ row.content.slice(0, 30) }}</template>
      </el-table-column>
      <el-table-column
        prop="remark"
        label="备注"
        align="center"
      ></el-table-column>
      <el-table-column label="操作" width="200" align="center">
        <template slot-scope="{ row, $index }">
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-edit"
            @click="showEditBox($index)"
          >
            编辑
          </el-button>
          <el-button
            type="danger"
            size="mini"
            icon="el-icon-delete"
            @click="handleDel(row.id)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!--    添加用户模态窗-->
    <el-dialog
      :title="handleType === 'add' ? '新增套餐' : '编辑套餐'"
      :visible.sync="dialogVisible"
      width="600px"
      center
    >
      <el-form ref="form" :model="singleArr" label-width="auto">
        <el-form-item label="名称">
          <el-input v-model="singleArr.name" />
        </el-form-item>
        <el-form-item label="内容">
          <el-input
            v-model="singleArr.content"
            :value="singleArr.content"
            type="textarea"
            rows="10"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="singleArr.remark" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          v-if="handleType === 'add'"
          type="primary"
          @click="handleAdd"
        >
          确 定
        </el-button>
        <el-button v-else type="primary" @click="handleEdit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api/setmeal';

export default {
  name: 'Setmeal',

  data() {
    return {
      dataList: [],
      dialogVisible: false,
      listLoading: false,
      handleType: 'add',
      checkboxArr: [],
      singleArr: {}
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.handleGetList();
  },

  methods: {
    //列表
    handleGetList() {
      this.listLoading = true;
      api.getAllListApi().then((response) => {
        this.dataList = response.data.data;
        this.listLoading = false;
      });
    },

    //新增
    handleAdd() {
      if (this.singleArr.name == '') {
        this.$message({
          type: 'error',
          message: '请填写套餐名称'
        });
        return;
      }
      if (this.singleArr.content == '') {
        this.$message({
          type: 'error',
          message: '请填写套餐内容'
        });
        return;
      }
      const query = {
        name: this.singleArr.name,
        content: this.singleArr.content,
        remark: this.singleArr.remark
      };
      api.addDataApi(query).then((res) => {
        if (res.code === 200) {
          this.handleGetList();
          this.dialogVisible = false;
          this.$message({
            type: 'success',
            message: '添加成功'
          });

          this.singleArr = {};
        }
      });
    },
    //删除

    handleDel(id) {
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        const query = { id: id };
        api.delDataApi(query).then((res) => {
          if (res.code === 200) {
            this.handleGetList();
            this.$message({
              type: 'success',
              message: '删除成功'
            });
          }
        });
      });
    },
    //批量删除

    batchDel() {
      if (this.checkboxArr.length == 0) {
        this.$message({
          type: 'error',
          message: '请至少选择一项'
        });
        return;
      }
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        const query = this.checkboxArr;
        api.delDataApi(query).then((res) => {
          if (res.code === 200) {
            this.handleGetList();
            this.$message({
              type: 'success',
              message: '删除成功'
            });
          }
        });
      });
    },

    showEditBox(num) {
      this.handleType = 'edit';
      this.singleArr = this.dataList[num];
      this.dialogVisible = true;
    },
    //编辑
    handleEdit() {
      const query = {
        id: this.singleArr.id,
        name: this.singleArr.name,
        contentText: this.singleArr.content,
        remark: this.singleArr.remark
      };
      api.updateDataApi(query).then((res) => {
        if (res.code === 200) {
          this.handleGetList();
          this.editBoxShow = false;
          this.$message({
            type: 'success',
            message: '修改成功'
          });
          this.dialogVisible = false;
          this.singleArr = {};
        }
      });
    },

    showAddBOX() {
      this.singleArr = {};
      this.dialogVisible = true;
    },
    handleSelectionChange(val) {
      this.checkboxArr = [];
      val.forEach((item) => {
        this.checkboxArr.push(item.id);
      });
    }
  }
};
</script>

<style scoped>
.web_wrap {
  width: 98%;
  padding: 2% 1%;
  position: relative;
}
</style>
