import request from '@/utils/request';

const Api = {
  async getAllListApi() {
    return await request.get('/medical/package');
  },

  async addDataApi(params) {
    return await request.post('/medical/package/create', params);
  },

  async delDataApi(params) {
    return await request.post('/medical/package/remove', params);
  },

  async updateDataApi(params) {
    return await request.post('/medical/package/update', params);
  },
  async getDetailApi(str) {
    return await request.get('/positionInfo/detail?id=' + str);
  }
};

export default Api;
